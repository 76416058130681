html,
body {
  padding: 0;
  margin: 0;
  font-family: Helvetica;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}
@font-face {
    font-family: 'MyFont';
    src: url('../assets/fonts/SuperNormalFont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


p {
  font-family: Helvetica;
  text-transform: none;
}
image {
    object-fit: cover;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #dcdbe0;
  border-radius: 5px;
}
.MuiPaper-root {
    .MuiAutocomplete-listbox {
        background: #F8FDE9 !important;
    };
    .MuiAutocomplete-noOptions {
        background: #F8FDE9 !important;
    }
}
.MuiMenu-paper  {
    border: 1px solid #B4E465 !important;
    background: #F8FDE9 !important;
    border-radius: 12px !important;
    box-shadow: 4px 4px 0 0 #B4E465 !important;
    max-height: 400px !important;
    font-size: 20px;
    margin-top: 7px;
    .MuiMenu-list {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        .Mui-selected {
            background-color: #D5E359 !important;
        }
        .Mui-disabled{
            color : #686868 !important;
        }
        .MuiTypography-body1 {
            color : #686868 !important;
        }
    }
}
.MuiPickersPopper-paper {
    border-radius: 20px !important;
    .MuiDateCalendar-root {
        border-radius: 20px !important;
        border : 1px solid #B4E465;
        box-shadow: 4px 4px 0 0 #B4E465 !important;
        .MuiPickersCalendarHeader-label {
            color : #697D21 !important;
        }
        .MuiPickersCalendarHeader-switchViewButton {
            color: #697D21 !important;
        }
        .MuiSvgIcon-fontSizeInherit {
            color: #697D21 !important;
        }
        .Mui-selected {
            background-color : #FE7098 !important;
        }
        .MuiPickersDay-root{
            color : #686868 !important;
        }
        .Mui-disabled{
            color : #ccc !important;
        }
        .MuiPickersDay-hiddenDaySpacingFiller{
            color : #C4C4C4 !important;
        }
        .MuiPickersYear-yearButton{
            color : #686868 !important;
        }
    }
}


@media (max-width: 700px) {
    .MuiPickersLayout-root {
        box-shadow: 4px 4px 0 0 #B4E465 !important;
        border : 1px solid #B4E465;
        .MuiDateCalendar-root {
            .MuiPickersCalendarHeader-label {
                color : #697D21 !important;
            }
            .MuiPickersCalendarHeader-switchViewButton {
                color: #697D21 !important;
            }
            .MuiSvgIcon-fontSizeInherit {
                color: #697D21 !important;
            }
            .Mui-selected {
                background-color : #FE7098 !important;
            }
            .MuiPickersDay-root{
                color : #686868 !important;
            }
            .Mui-disabled{
                color : #ccc !important;
            }
            .MuiPickersDay-hiddenDaySpacingFiller{
                color : #C4C4C4 !important;
            }
            .MuiPickersYear-yearButton{
                color : #686868 !important;
            }
        }
        .MuiPickersToolbar-root {
            .MuiPickersToolbar-content{
                .MuiTypography-root  {
                    color : #686868 !important;
                }
            }
        }
    }
}
